





































































































































































import ReputationMessageHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import ReputationCommonSettings from "@/components/HelpViews/Reputation/ReputationCommonSettings.vue";
import { InputSetups } from "@/mixins/input-setups";
import { REPUTATION_TYPES } from "@/includes/logic/TypedChipsData";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import ReputationReactions from "@/components/Modules/modules/Reputation/ReputationReactions.vue";

import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue';
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import MultiMessageEditorWithMediaInput
  from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import {
  MediaEditorMode
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import TypedChipsInput from "piramis-base-components/src/components/Pi/fields/TypedChipsInput/TypedChipsInput.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    ReputationReactions,
    ExtendedHelpMessage,
    SwitchInput,
    TimeUnitInput,
    MultiMessageEditorWithMediaInput,
    NestedContent,
    TypedChipsInput,
    Icon,

  },
  data() {
    return {
      ReputationCommonSettings,
      REPUTATION_TYPES,
      EditorButtonsHelpView,
      MediaEditorMode
    }
  }
})
export default class MainReputationSettings extends Mixins(InputSetups, Placeholders, TariffsTagsHelper) {
  @VModel() module!: ReputationMessageHandlerModuleBuilder

  get positiveMessage() {
    return this.module.model.params.positive.message
  }

  set positiveMessage(message: ReputationMessageHandlerModuleBuilder['model']['params']['negative']['message']) {
    this.module.model.params.positive.message = message
  }

  get negativeMessage() {
    return this.module.model.params.negative.message
  }

  set negativeMessage(message: ReputationMessageHandlerModuleBuilder['model']['params']['negative']['message']) {
    this.module.model.params.negative.message = message
  }
}
