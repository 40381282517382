import { render, staticRenderFns } from "./MainReputationSettings.vue?vue&type=template&id=838dfc8e&scoped=true&"
import script from "./MainReputationSettings.vue?vue&type=script&lang=ts&"
export * from "./MainReputationSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "838dfc8e",
  null
  
)

export default component.exports