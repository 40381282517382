














































import { InputSetups } from '@/mixins/input-setups'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import ReputationTrigger
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationTrigger";
import { frontendChips } from "piramis-base-components/src/components/TypedChips/typedChipsLogic";
import { BASE_SUPER_LIST_TYPES } from "@/includes/logic/TypedChipsData";
import ChipsList from 'piramis-base-components/src/components/TypedChips/components/ChipsList.vue';

@Component({
  components: {
    ChipsList
  }
})
export default class ReputationTriggerCard extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() item!:ReputationTrigger

  get frontendTriggers() {
    return frontendChips(this.item.triggers, BASE_SUPER_LIST_TYPES)
  }

  @Emit()
  edit() {
    return this.item
  }

  @Emit('delete')
  deleteTrigger() {
    return this.item
  }
}
