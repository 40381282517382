





















































































import { InputSetups } from "@/mixins/input-setups";
import {
  ReputationValueType
} from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModule";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Mixins, VModel, Prop } from 'vue-property-decorator';

@Component({
  components: {
    NestedContent,
    SelectInput,
    NumberInput
  }
})
export default class ReputationReactions extends Mixins(InputSetups, SelectOptionsMixin) {
  @VModel() model!: ReputationValueType

  @Prop() prefix!: string

}
