var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reputation-reactions"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'actor_reaction_emoji',
        'options': _vm.emojiOptions,
        'prefix': _vm.prefix,
        'hasAnchor': true,
        'settingWrapper': {
          'customiseValue': _vm.emojiOptions[0],
          'value': { value: null },
        },
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'target_reaction_emoji',
        'options': _vm.emojiOptions,
        'prefix': _vm.prefix,
        'hasAnchor': true,
        'settingWrapper': {
          'customiseValue': _vm.emojiOptions[0],
          'value': { value: null },
        },
      }
    }}}),(0 < _vm.model.reaction_count && _vm.model.reaction_count < 4)?_c('a-alert',{attrs:{"type":"warning","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('reputation_reaction_count_alert_message'))},slot:"message"})]):_vm._e(),_c('number-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'reaction_count',
        'prefix': _vm.prefix,
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        'hasAnchor': true,
        'settingWrapper': {
          customiseValue: 5,
          hasFieldButton: true,
          disabledValue: 0
        },
      }
    }}}),(_vm.model.reaction_count > 0)?_c('nested-content',[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'special_reactions',
          'multiple': true,
          'options': _vm.emojiOptions,
          'prefix': _vm.prefix,
          'hasAnchor': true,
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }