
















import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class ReputationCommonSettings extends Vue {
  goToRanksHelpMessage(): void {
    this.$router.push({
      name: 'rank_system',
      params: {
        [EntityTypes.CHAT_ID]: this.$route.params[EntityTypes.CHAT_ID]
      }
    })
  }
}
